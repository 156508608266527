/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-29 15:05:10
 * @Module: 推广
 */
 <template>
  <div class="page">
    <div class="banner">
      <div class="ban">
        <div class="left">
          <div class="bigtxt">让您的商业信息价值最大化</div>
          <p><span>高效广告管理</span><span>便捷数据分析</span></p>
          <div class="line"></div>
          <div class="btn">预约咨询</div>
        </div>
        <div class="rImg">
          <img src="@/static/promotion/ban.png" alt="" />
        </div>
        <div class="lImg">
          <img src="@/static/promotion/small.png" alt="" />
        </div>
        <div class="bImg">
          <img src="@/static/promotion/big.png" alt="" />
        </div>
      </div>
    </div>
    <contente/>
    <foot/>
  </div>
</template>
 <script>
 import foot from './module/foot'
 import contente from './module/content'
export default {
  data() {
    return {};
  },
  components: {
    foot,
    contente
  },
  mounted() {},
  methods: {}
};
</script>
 <style lang="scss" scoped>
.page {
  background: #f7f7f7;
  min-height: 100vh;
  border: solid 1px #f7f7f7;
  overflow: hidden;
}
.page-content {
  width: 1240px;
  margin: 0 auto;
}
.banner {
  width: 100%;
  padding-top: 60px;
  background: #1e49d3;
  display: flex;
  position: relative;
  .ban {
    width: 1240px;
    height: 630px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      .bigtxt {
        width: 420px;
        height: 134px;
        font-size: 48px;
        font-weight: 500;
        color: #ffffff;
        line-height: 67px;
        letter-spacing: 12px;
      }
      p {
        margin-top: 20px;
        span {
          height: 28px;
          font-size: 20px;
          color: #ffffff;
          line-height: 28px;
          margin-right: 5px;
        }
      }
      .line {
        width: 45px;
        height: 6px;
        background: #ff6700;
        border-radius: 3px;
        margin: 20px auto;
        margin-left: 1px;
      }
      .btn {
        width: 120px;
        height: 43px;
        background: rgba(255, 255, 255, 0.06);
        border-radius: 4px;
        border: 1px solid #ffffff;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        line-height: 43px;
        cursor: pointer;
      }
    }
    .rImg {
      width: 704px;
      height: 630px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .lImg {
      position: absolute;
      top:28%;
      left: 40%;
    }
    .bImg {
      position: absolute;
      top:69%;
      left: 34%;
    }
  }
}
</style>